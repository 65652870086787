import React from 'react'
import "./NavBar.css";
function NavBar() {

  return (
    <div className= "navbar">
        <img className="logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Netflix_2015_logo.svg/1920px-Netflix_2015_logo.svg.png" alt="NEtflix Logo" />
        <img className="avatar" src="https://i.pinimg.com/originals/0d/dc/ca/0ddccae723d85a703b798a5e682c23c1.png" alt="Avatar" />

         <div class="navbar-home">
             <h4 className="core">Home</h4>
             <h4 className="core1">TV Shows</h4>
             <h4 className="core2">Movies</h4>
             <h4 className="core3">Recently Added</h4>
             <h4 className="core4">My List</h4>

         </div>
        
    </div>
  )
}

export default NavBar